"use client";

import { cn } from '@/lib/utils';
import { Inter as FontSans, Vazirmatn as FontFarsi } from 'next/font/google';
import { FC, HTMLAttributes } from 'react';
const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans',
  fallback: ['system-ui', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji']
});
const fontFarsi = FontFarsi({
  subsets: ['arabic', 'latin'],
  fallback: ['var(--font-sans)', 'sans-serif'],
  variable: '--font-farsi'
});
interface BodyProps extends HTMLAttributes<HTMLBodyElement> {}

// ServerSide-Component THAT LOADS IN FONTS
// THIS COMPONENT CAN NOT BE USED IN ANY CLIENT-SIDE COMPONENT
// IT IS BEING USED TO LOAD IN FONTS
const Body: FC<BodyProps> = ({
  children,
  className,
  ...rest
}) => {
  return <body className={cn('min-h-screen bg-background font-sans antialiased flex flex-col relative', fontSans.variable, fontFarsi.variable, className)} {...rest} data-sentry-component="Body" data-sentry-source-file="body.tsx">
	{children}
	</body>;
};
export default Body;