// For server-side
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const PORT = process.env.PORT || '3000';

// For both server-side and client-side
export const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'https://kheradmen.com';
export const NEXT_PUBLIC_BACKEND_API = process.env.NEXT_PUBLIC_BACKEND_API ||
	(process.env.NODE_ENV === 'development'
		? 'http://localhost:8081'
		: 'https://api.kheradmen.com');

export const DEFAULT_PROJECT_ID = process.env.NEXT_PUBLIC_DEFAULT_PROJECT_ID || 'p5xqpV1M8W';