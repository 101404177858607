'use client';

import { useMounted } from '@/hooks/use-mounted';
import dynamic from 'next/dynamic';
import React, { memo } from 'react';

// Dynamically import the CookieConsent component
// This ensures it's only loaded on the client-side
const CookieConsent = dynamic(() => import('./cookie-consent').then(mod => mod.default), {
  ssr: false
});
export const ClientSideCookieConsent: React.FC = () => {
  // Use the useMounted hook to check if the component has mounted
  const isMounted = useMounted();

  // Only render the CookieConsent component when the component is mounted
  // This prevents any server-side rendering issues
  if (!isMounted) {
    return null;
  }
  return <CookieConsent data-sentry-element="CookieConsent" data-sentry-component="ClientSideCookieConsent" data-sentry-source-file="cookie-consent-client.tsx" />;
};

// Memoize the component to prevent unnecessary re-renders
export default memo(ClientSideCookieConsent);