'use client';

import { Button } from '@/components/ui/button';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronUp } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
const ScrollToTop: React.FC = () => {
  const [show, setShow] = useState(false);
  const handleScroll = useCallback(() => {
    setShow(window.scrollY > 100);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="ScrollToTop" data-sentry-source-file="scroll-to-top.tsx">
			{show && <motion.div initial={{
      opacity: 0,
      scale: 0.5
    }} animate={{
      opacity: 1,
      scale: 1
    }} exit={{
      opacity: 0,
      scale: 0.5
    }} transition={{
      duration: 0.3
    }} className="fixed bottom-6 right-6 z-50">
					<Button onClick={scrollToTop} variant="default" size="icon" className="rounded-full bg-primary/80 hover:bg-primary dark:bg-secondary/80 dark:hover:bg-secondary text-primary-foreground dark:text-secondary-foreground shadow-lg hover:shadow-xl transition-all duration-300" aria-label="Scroll to top">
						<ChevronUp className="h-5 w-5" />
					</Button>
				</motion.div>}
		</AnimatePresence>;
};
export default ScrollToTop;