import { NEXT_PUBLIC_BASE_URL, PORT } from '@/env';
import { LocalePrefix, Pathnames }    from 'next-intl/routing';
import { getRequestConfig }           from 'next-intl/server';



export const i18n = {
	locales: ['fa', 'en'] as const,
	defaultLocale: 'fa'
} as const;

export type Locale = (typeof i18n.locales)[number];

export const getDirection = (locale: Locale): 'rtl' | 'ltr' =>
	locale === 'fa' ? 'rtl' : 'ltr';

export const nextStaticGeneration = {
	dynamicParams: false,
	generateStaticParams: () => i18n.locales.map((locale) => ({locale}))
};

export default getRequestConfig(async ({locale}) => ({
	messages: (await import(`./messages/${ locale }.json`)).default,
	now: new Date(),
	timeZone: locale === 'en' ? 'Europe/London' : 'Asia/Tehran',
	formats: {
		dateTime: {
			short: {
				day: 'numeric',
				month: 'short',
				year: 'numeric'
			}
		},
		number: {
			precise: {
				maximumFractionDigits: 5
			}
		},
		list: {
			enumeration: {
				style: 'long',
				type: 'conjunction'
			}
		}
	}
}));

export const locales = i18n.locales;
export const defaultLocale = i18n.defaultLocale;
export const localePrefix: LocalePrefix<typeof locales> = 'as-needed';


export const port = PORT || 3000;
export const host = NEXT_PUBLIC_BASE_URL ? NEXT_PUBLIC_BASE_URL : `http://127.0.0.1:${ port }`;


export const pathnames: Pathnames<typeof locales> = {
	'/': '/',
	'/careers': {
		'en': '/careers',
		'fa': '/فرصت-شغلی'
	},
	'/blog': {
		'en': '/blog',
		'fa': '/بلاگ'
	}
};

type PN = typeof pathnames;
export type {
	PN as Pathnames  // Pathnames
};


export type Pages = 'careers' | 'blog' | 'products' | 'about';

