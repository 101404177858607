'use client';

import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import React from 'react';
type LocaleProviderProps = {
  locale: string;
  messages: AbstractIntlMessages;
  children: React.ReactNode;
  timeZone?: string;
};
const LocaleProvider: React.FC<LocaleProviderProps> = ({
  children,
  locale,
  messages,
  timeZone
}) => {
  if (!timeZone) {
    timeZone = 'Asia/Tehran';
  }
  return <NextIntlClientProvider locale={locale} messages={messages} timeZone={timeZone} defaultTranslationValues={{
    strong: chunks => <strong>{chunks}</strong>
  }} data-sentry-element="NextIntlClientProvider" data-sentry-component="LocaleProvider" data-sentry-source-file="LocaleProvider.tsx">
			{children}
		</NextIntlClientProvider>;
};
export default LocaleProvider;